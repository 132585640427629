require('./bootstrap');

import 'slick-carousel/slick/slick';
import 'corejs-typeahead';
import "./modules/theme";
import "./modules/feather";
import "./modules/sidebar";


require('./plugins/imask');
require('./imask');
require('./masks');
require('./global');
require('./validate.min.js');
require('./validatePlus.js');

