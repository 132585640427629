

var maskInputs = function () {
    
    var phone = function() {
        phones = document.querySelectorAll(".phone");
        for (var i = 0; i < phones.length; i++) {
            IMask(phones[i], {
                mask: [
                    {
                    mask:'(00) 0000-0000',
                    },
                    {
                    mask:'(00) 00000-0000',
                    },
                ],
            });
        }
    }

    var cpf = function() {
        cpfs = document.querySelectorAll(".cpf");
        for (var i = 0; i < cpfs.length; i++) {
            IMask(cpfs[i], {
                mask:'000.000.000-00',
            });
        }
    }

    var cnpj = function() {
        cnpjs = document.querySelectorAll(".cnpj");
        for (var i = 0; i < cnpjs.length; i++) {
            IMask(cnpjs[i], {
                mask:'00.000.000/0000-00',
            });
        }
    }

    var cnh = function() {
        cnhs = document.querySelectorAll(".cnh");
        for (var i = 0; i < cnhs.length; i++) {
            IMask(cnhs[i], {
                mask:'00000000000',
            });
        }
    }

    //^(?!0\.00)[1-9]\d{0,2}(,\d{3})*(\.\d\d)?$
    var currencyMask = function(){

        prices = document.querySelectorAll(".price");
        for (var i = 0; i < prices.length; i++) {
            IMask(prices[i], {
                mask: [
                    { mask: '' },
                    {
                        mask: 'R$ num',
                        lazy: false,
                        blocks: {
                            num: {
                                mask: Number,
                                scale: 2,
                                thousandsSeparator: '.',
                                padFractionalZeros: true,
                                radix: ',',
                                mapToRadix: ['.'],
                            }
                        }
                    }
                ],
            });
        }

    }
    return {
        init: function() {
            phone();
            cpf();
            cnpj();
            cnh();
            currencyMask();
        }
    };
}();
    
$(function () {
    
    
    maskInputs.init();
});
