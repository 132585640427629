window.apiClient = axios.create({
    baseURL: '',
    withCredentials: true,
    crossDomain: true,
    headers: {
        "content-type": "application/json"
    }
});

window.globalCopy = function(id) {
  var copyText = document.querySelector(id);
  copyText.select();
  document.execCommand("copy");
}

window.HandlingError = function(error, validator){
    if (error.response) {
        if(error.response.status==422){
          console.log(error.response.data.errors);
          validator.resetForm();
          try {
            validator.showErrors(error.response.data.errors);
          } catch (error) {
            console.log(error);
          }

          
        }else{
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
        
        
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      //console.log(error.config);
};


$(function(){
  if($('.copy')[0]){
    $('.copy').on('click',function(){
      globalCopy($(this).data('id'));
    });
  }

  if($('.copySpan')[0]){
    $('.copySpan').on('click',function(){
      var $temp = $("<input>");
      $("body").append($temp);
      $temp.val($(this).text()).select();
      document.execCommand("copy");
      $temp.remove();
     
    });
  }

  if($('.data-toggle')[0]){
    $('.data-toggle').each(function() {
      $('div[' + $( this ).data('toggle') + ']').hide();
    });
    $('.data-toggle:checked').each(function() {
      $('div[' + $( this ).data('toggle') + '=' + $( this ).val() + ']').show();
    });
    $('.data-toggle').on('click', function(){
      $('div[' + $( this ).data('toggle') + ']').hide();
      $('div[' + $( this ).data('toggle') + '=' + $(this).val() + ']').toggle()
    }); 
  }

});


